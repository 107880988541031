<template>
    <Guest>
        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-xl">
            <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <h1 class="pb-4 text-center text-lg">Pagina <b>{{ current_route }}</b> niet gevonden</h1>
                <router-link :to="{ name: dest} ">
                    <button class="button bg-gradient-to-r from-gradient-orange to-gradient-red w-full p-4 rounded text-white font-medium">
                        Terug naar {{ dest }}
                    </button>
                </router-link>
            </div>
        </div>
    </Guest>
</template>

<script>
import Guest from '@/views/wrappers/Guest';

export default {
    name: "NotFound",
    components: { 
        Guest, 
    },
    
    computed: {
        dest: () => {
            return 'login'; 
        },
        current_route: function(){
            return this.$route.fullPath
        }
    }
}
</script>